










import { Component, Prop, Mixins } from 'vue-property-decorator'
import StyleSubjectBackground from '@/components/atoms/StyleSubjectBackground.vue'
import StepProgress from '@/components/molecules/StepProgress.vue'

@Component({
  components: {
    StepProgress,
  },
})
export default class SubjectProgress extends Mixins(StyleSubjectBackground) {
  @Prop()
  subjectCode!: string

  @Prop()
  ratio!: number

  @Prop()
  totalStep!: number

  @Prop()
  currentStep!: number

  @Prop({ default: null })
  stepLabels?: []

  @Prop({ default: false })
  isTeacher?: boolean

  get progressStyle(): {} {
    const ret: any = {}
    const _: any = this
    if (_.isTeacher) {
      ret['fill-color'] = 'var(--color-blue)'
      ret['balloon-color'] = 'var(--color-blue-3)'
      ret['balloon-text-color'] = 'var(--color-blue)'
      ret['bar-color'] = 'var(--color-blue-3)'
    } else {
      const subjectStyle = _.getSubjectStyle(this.subjectCode)
      ret['fill-color'] = subjectStyle['--subject-bg-color']
      ret['gradient-color'] = 'var(--color-white)'
      ret['balloon-color'] = subjectStyle['--subject-bg-color-light']
      ret['balloon-text-color'] = subjectStyle['--subject-bg-color']
    }
    return ret
  }
}
