












import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class SelectMultiple extends Vue {
  @Prop()
  selectOptions!: []

  @Prop({ default: null })
  onChangeFunction?: any

  @Prop({ default: null })
  selected?: null | string | number

  @Prop({ default: 10 })
  linesize?: number

  @Prop({ default: false })
  disabled?: boolean

  private changeSelect(event: Event): void {
    const selectedOptions = Array.from((event.target as HTMLSelectElement).selectedOptions)
    this.$emit(
      'input',
      selectedOptions.map((opt) => opt.value)
    )
    if (this.onChangeFunction !== null) this.onChangeFunction(event)
  }

  private clickOption(event: Event): void {
    if (this.onChangeFunction !== null) this.onChangeFunction(event)
  }
}
