












import { Component, Prop, Vue } from 'vue-property-decorator'
@Component
export default class PopupBase extends Vue {
  @Prop()
  width?: number

  @Prop()
  grayColor?: boolean

  @Prop()
  hasFooter?: boolean

  private clickEvent(key: Event): void {
    this.$emit('click-close', key)
  }
}
