














































import { Component, Vue, Prop, Ref, Watch } from 'vue-property-decorator'
import PopupBase from '@/components/molecules/v3/PopupBase.vue'
import SelectUnit from '@/views/student/v3/SelectUnit.vue'
import CheckboxSquare from '@/components/atoms/CheckboxSquare.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import DatePickerIcon from '@/components/atoms/DatePickerIcon.vue'
import SidebarSwitcher from '@/components/organisms/v2/SidebarSwitcher.vue'
import moment from 'moment'

export interface Subject {
  id?: string | number
  name?: string
  checked: boolean
}

interface Unit {
  id: string
  name: string
  gradeId: number
}

const YYYY_MM_DD = 'YYYY-MM-DD'

@Component({
  components: {
    PopupBase,
    SelectUnit,
    CheckboxSquare,
    ButtonBase,
    DatePickerIcon,
    SidebarSwitcher,
  },
})
export default class PopupLearningPlan extends Vue {
  @Prop()
  show?: boolean

  @Prop()
  handleClose!: () => void

  @Ref() selectUnitRef!: any

  @Watch('show')
  private watchFetchSettingStudent() {
    this.loadData()
  }

  private showLearningEachSubject = false
  private targetUnits: { [subjectId: string]: Unit[] } = {}
  private subjects: Subject[] = []
  private period = ''
  private startAt = ''
  private endAt = ''
  private currentDate = new Date()

  private branchId = Vue.prototype.$cookies.get('dataGdls').branchId
  private grades: any[] = []

  private gradeNames: any = {}

  private get colortype(): string {
    const permitted = this.targetUnits && this.startAt && this.startAt
    return permitted ? 'blue' : 'pointer-events-none'
  }

  private handleCloseAndReset() {
    this.handleResetData()
    this.handleClose()
  }

  private onChangeRangeDate(params: { start: moment.Moment; end: moment.Moment }) {
    const start = params.start.format(YYYY_MM_DD)
    const end = params.end.format(YYYY_MM_DD)
    this.startAt = start
    this.endAt = end
  }

  private async fetchSettingStudent() {
    const formatDate = 'LL'
    const classMode = this.$route.params.classMode
    const { data } = await Vue.prototype.$http.httpWithToken.get(`/v3/student_class_mode_settings/${classMode}`)
    if (!data) return
    const startAt = moment(data.study_period.startAt)
    const endAt = moment(data.study_period.endAt)
    this.startAt = startAt.format(YYYY_MM_DD)
    this.endAt = endAt.format(YYYY_MM_DD)
    this.period =
      startAt.format('YYYY年MM月DD日') +
      '~' +
      (startAt.format('YYYY') === endAt.format('YYYY') ? endAt.format('MM月DD日') : endAt.format(formatDate))
    const learningRange = data.learning_range
    const objUnit = {}
    learningRange.forEach(({ subjectId, gradeId, units }: any) => {
      objUnit[subjectId] = []
      this.subjects.forEach((subject: Subject) => {
        if (subject.id == subjectId) subject.checked = true
      })
      units.forEach((unit: Unit) => {
        objUnit[subjectId].push({ ...unit, name: `${this.gradeNames[gradeId]} ${unit.name}`, gradeId })
      })
    })
    this.targetUnits = objUnit
  }

  private handleResetData() {
    this.targetUnits = {}
    this.selectUnitRef[0].resetTargetUnit()
    this.subjects = this.subjects.map((subject, index) => {
      if (index == 0) return { ...subject, checked: true }
      return { ...subject, checked: false }
    })
  }

  private async submitSetting() {
    const data = {}
    const dataPayload = []
    const targetUnitsClone = this.targetUnits
    const subjectChecked: number[] = []
    this.subjects.forEach((subject: any) => {
      if (subject.checked) {
        subjectChecked.push(+subject.id)
      }
    })
    for (const subjectId in targetUnitsClone) {
      if (subjectChecked.includes(+subjectId)) {
        data[subjectId] = {}
        targetUnitsClone[subjectId].forEach((unit: any) => {
          if (data[subjectId][unit.gradeId]) {
            data[subjectId][unit.gradeId] = [...data[subjectId][unit.gradeId], unit.id]
          } else {
            data[subjectId][unit.gradeId] = [unit.id]
          }
        })
      }
    }
    for (const subjectId in data) {
      for (const gradeId in data[subjectId]) {
        dataPayload.push({ subjectId: subjectId, gradeId: gradeId, units: data[subjectId][gradeId] })
      }
    }
    try {
      const classMode = this.$route.params.classMode
      await Vue.prototype.$http.httpWithToken.patch(`/v3/student_class_mode_settings/${classMode}`, {
        learning_range: {
          data: dataPayload,
        },
        study_period: {
          startAt: this.startAt,
          endAt: this.endAt,
        },
      })
      alert('正常に更新')
      this.handleClose()
    } catch {
      alert('エラー')
    }
  }

  private handleChangeTargetUnit(_targetUnits: any) {
    const key = Object.keys(_targetUnits)[0]
    if (_targetUnits[key].length == 0) {
      delete this.targetUnits[key]
    } else {
      this.targetUnits = { ...this.targetUnits, ..._targetUnits }
    }
  }

  private async loadSeasonSettings() {
    await Vue.prototype.$http.httpWithToken
      .get('/v3/seasonSettings', { params: { branchId: this.branchId } })
      .then((res: any) => {
        const _grades = res.data.map((grade: any) => {
          return {
            gradeId: grade.gradeId,
            gradeName: grade.gradeName,
          }
        })
        _grades.sort((a: any, b: any) => a.gradeId - b.gradeId)
        this.grades = _grades
      })
  }
  private async loadGradeName() {
    await Vue.prototype.$http.httpWithToken.get('/grades', { params: { branchId: this.branchId } }).then((res: any) => {
      const objGrade = {}
      res.data.forEach((grade: any) => {
        objGrade[grade.id] = grade.name
      })
      this.gradeNames = objGrade
    })
  }
  private async fetchListSubject() {
    const response = await Vue.prototype.$http.httpWithToken.get('/subjects')
    this.subjects = response.data.map((subject: any, index: number) => {
      let checked = false
      if (index === 0) {
        checked = true
      }
      return { id: subject.id, name: subject.name, checked, code: subject.code }
    })
  }

  private async loadData() {
    Vue.prototype.$loading.start()
    await this.loadSeasonSettings()
    await this.loadGradeName()
    await this.fetchListSubject()
    await this.fetchSettingStudent()
    Vue.prototype.$loading.complete()
  }

  private mounted() {
    this.loadData()
  }
}
