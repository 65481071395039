




























import { Component, Prop, Vue } from 'vue-property-decorator'
import moment from 'moment'

@Component
export default class DatePickerIcon extends Vue {
  @Prop({ default: null })
  date!: any

  @Prop({ default: 'LL' })
  format!: string

  @Prop({ default: 'single' })
  mode!: string

  @Prop({ default: null })
  onSetRangeDate?: { func: Function; funcParams: {} } | null

  @Prop({ default: null })
  onSelectDate?: { func: Function; funcParams: {} } | null

  @Prop({ default: null })
  minDate?: Date | string | null

  @Prop({ default: null })
  maxDate?: Date | string | null

  @Prop({ default: null })
  columns?: any

  @Prop({ default: 'en' })
  locale?: string

  get selectedDate(): any {
    return this.date
  }

  set selectedDate(value) {
    if (this.mode == 'range') {
      this.setRangeDate(value)
      return
    }

    const selected = moment(value)
    selected.locale('ja')
    if (this.onSelectDate) {
      const params = this.onSelectDate.funcParams
      params['date'] = selected
      this.onSelectDate.func(params)
    }

    this.$emit('input', selected.format(this.format))
  }

  private setRangeDate(value: { start: any; end: any }) {
    const start = moment(value.start)
    const end = moment(value.end)
    const format = this.format
    start.locale('ja')
    end.locale('ja')
    if (this.onSetRangeDate) {
      const params = this.onSetRangeDate.funcParams
      params['start'] = start
      params['end'] = end
      this.onSetRangeDate.func(params)
    }
    this.$emit(
      'input',
      start.format(format) +
        '~' +
        (start.format('YYYY') === end.format('YYYY') ? end.format('MM月DD日') : end.format(format))
    )
  }
}
