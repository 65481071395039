



























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class StepProgress extends Vue {
  @Prop({ default: 0 })
  ratio!: number

  @Prop()
  totalStep!: number

  @Prop({ default: 0 })
  currentStep!: number

  @Prop()
  stepLabels?: []

  @Prop()
  fillColor?: string

  @Prop()
  balloonColor?: string

  @Prop()
  balloonTextColor?: string

  @Prop()
  barColor?: string

  @Prop()
  gradientColor?: string

  get setStyle(): { [key: string]: string } {
    const barColor = this.barColor ? this.barColor : this.fillColor
    return {
      '--ratio': `${this.ratio / 100}`,
      '--total-step-count': `${this.totalStep}`,
      '--achieved-step-count': `${this.currentStep - 1}`,
      '--fill-color': this.fillColor ? this.fillColor : 'var(--color-gray-6)',
      '--baloon-color': this.balloonColor ? this.balloonColor : 'var(--color-gray-1)',
      '--baloon-text-color': this.balloonTextColor ? this.balloonTextColor : 'var(--color-gray-6)',
      '--background':
        this.gradientColor && barColor
          ? `linear-gradient(to right, ${this.gradientColor},${barColor})`
          : barColor || 'var(--fill-color)',
    }
  }

  private calcBarLength(): number {
    const unitRatio = 100 / this.totalStep
    const stepStartPoint = unitRatio * this.currentStep
    const stepRatio = unitRatio * (this.ratio / 100)
    return (stepStartPoint + stepRatio) / 100
  }

  private stepAreaClass(index: number): {} {
    return {
      'step-progress__step-bar__step-area__step-achieved': this.currentStep > index,
      'step-progress__step-bar__step-area__step-unachieved': this.currentStep <= index,
    }
  }

  private stepLabelClass(index: number): {} {
    return {
      'step-progress__step-information__label-achieved': this.currentStep > index,
      'step-progress__step-information__label-unachieved': this.currentStep <= index,
    }
  }

  private getStepStyle(index: number): {} {
    const point = index === this.totalStep ? 100 : (100 / this.totalStep) * index
    return {
      '--step-pos': `${point}%`,
    }
  }
}
