








import { Component, Prop, Vue } from 'vue-property-decorator'

export type TabOption = { id: number; name: string }

@Component
export default class TabBase extends Vue {
  @Prop()
  tabs!: TabOption[]

  @Prop()
  value!: number

  @Prop()
  type?: string

  @Prop()
  width?: string

  get classObject(): string {
    const { type } = this
    return type || 'button-center'
  }

  get baseStyles(): { [key: string]: string } {
    return {
      '--width': this.width ? this.width : '50px',
    }
  }

  private change(newValue: number): void {
    this.$emit('input', newValue)
  }
}
