


























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import TabBase from '@/components/atoms/TabBase.vue'
import SelectMultiple from '@/components/atoms/SelectMultiple.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'

@Component({
  components: {
    TabBase,
    SelectMultiple,
    ButtonBase,
  },
})
export default class SelectUnit extends Vue {
  @Prop()
  private seasonSettingId!: number

  @Prop()
  private targetInit!: { id: string; name: string, gradeId: number }[]

  @Prop()
  private changeTargetUnit!: (targetUnits: any) => void

  @Prop()
  private subjectName!: string

  @Prop()
  private subjectCode!: string

  private tabBaseDatas: { id: number; name: string }[] = []
  private gradeId = this.tabBaseDatas[0]?.id || 0
  private seasonId = 0
  private gradeCode = ''
  private studyUnits: { value: string; text: string }[] = []
  private targetUnits: { value: string; text: string }[] = []
  private selectedUnits: string[] = []
  private selectedTargetUnits: string[] = []
  private branchId = Vue.prototype.$cookies.get('dataGdls').branchId

  private get colortype(): string {
    return this.targetUnits.length > 0 ? 'blue' : 'pointer-events-none'
  }

  @Watch('gradeId')
  onGradeIdChanged(newGradeId: number, oldGradeId: number) {
    if (oldGradeId !== 0) {
      this.loadChangedStudyUnits()
    }
  }

  @Watch('seasonSettingId')
  async onSeasonIdChanged(newSeasonId: number) {
    if (newSeasonId !== 0) {
      Vue.prototype.$loading.start()
      await this.loadSeminarUnit()
      Vue.prototype.$loading.complete()
    }
  }

  private async mounted() {
    this.targetUnits = this.targetInit.map((item) => {
      return {
        gradeId: item.gradeId,
        text: item.name,
        value: item.id + '',
      }
    })
    this.postSeasonSettings()
    await this.loadSeminarUnit()
    await this.getGrades()
    await this.loadStudyUnits(this.subjectCode)
  }

  private async loadChangedStudyUnits() {
    Vue.prototype.$loading.start()
    await this.loadStudyUnits(this.subjectCode)
    Vue.prototype.$loading.complete()
  }

  private addUnit(): void {
    this.selectedUnits.forEach((unit_value: string) => {
      const obj = this.studyUnits.find((unit: { value: string; text: string }) => {
        return unit.value === unit_value
      })
      if (!obj) return
      this.targetUnits.push(Object.assign({}, { ...obj, gradeId: this.gradeId }))
      this.$set(obj, 'visible', false)
    })
    this.postSeasonSettings()
  }

  public resetTargetUnit () {
    this.targetUnits = []
  }

  private removeUnit(): void {
    this.selectedTargetUnits.forEach((unit_value: string) => {
      const obj = this.studyUnits.find((unit: { value: string; text: string }) => {
        return unit.value === unit_value
      })
      if (!obj) return
      this.targetUnits = this.targetUnits.filter((unit: { value: string; text: string }) => {
        return unit.value !== unit_value
      })
      this.$set(obj, 'visible', true)
    })
    this.postSeasonSettings()
  }
  private upUnit(): void {
    // 一番上の項目選択時は動作しない
    if (this.selectedTargetUnits.includes(this.targetUnits[0].value)) return
    this.selectedTargetUnits.forEach((unit_value: string) => {
      const idx = this.targetUnits.findIndex((unit: { value: string; text: string }) => {
        return unit.value === unit_value
      })
      this.targetUnits = this.reverseTargetUnits(idx, idx - 1)
    })
  }
  private downUnit(): void {
    // 一番下の項目選択時は動作しない
    if (this.selectedTargetUnits.includes(this.targetUnits[this.targetUnits.length - 1].value)) return
    this.selectedTargetUnits.forEach((unit_value: string) => {
      const idx = this.targetUnits.findIndex((unit: { value: string; text: string }) => {
        return unit.value === unit_value
      })
      this.targetUnits = this.reverseTargetUnits(idx, idx + 1)
    })
  }

  private reverseTargetUnits(from_idx: number, to_idx: number): { value: string; text: string }[] {
    const result: { value: string; text: string }[] = []
    this.targetUnits.forEach((unit: { value: string; text: string }, index) => {
      if (index === from_idx) result.push(this.targetUnits[to_idx])
      else if (index === to_idx) result.push(this.targetUnits[from_idx])
      else result.push(unit)
    })
    return result
  }

  private filterStudyUnits(): void {
    this.targetUnits.forEach((t_unit: { value: string; text: string }) => {
      const obj = this.studyUnits.find((unit: { value: string; text: string }) => {
        return unit.value === t_unit.value
      })
      if (!obj) return
      this.$set(obj, 'visible', false)
    })
  }

  private async loadSeminarUnit() {
    // await Vue.prototype.$http.httpWithToken.get(`/seasonSettings/${this.seasonSettingId}/setting`).then((res: any) => {
    //   this.subjectCode = res.data.seasonSetting.subjectCode
    //   this.gradeId = res.data.seasonSetting.gradeId
    //   this.gradeCode = res.data.seasonSetting.gradeCode
    //   this.seasonId = res.data.seasonSetting.seasonId
    //   this.loadStudyUnits(res.data.seasonSetting.subjectCode)
    // })
  }

  private async getGrades() {
    await Vue.prototype.$http.httpWithToken.get(`/grades`).then((res: any) => {
      this.tabBaseDatas = res.data.map((grade: { id: number; name: string }) => {
        return {
          id: grade.id,
          name: grade.name,
        }
      })
      this.gradeId = this.tabBaseDatas[0].id
    })
  }

  private postSeasonSettings() {
    const settings = this.targetUnits.map((unit: { value: string; text: string; gradeId?: number }) => {
      return {
        id: parseInt(unit.value),
        name: unit.text,
        gradeId: unit.gradeId,
      }
    })
    this.changeTargetUnit({ [this.seasonSettingId]: [...settings] })
  }

  private async loadStudyUnits(subjectCode: string) {
    await Vue.prototype.$http.httpWithToken
      .get(`/publisherCurriculums/curriculumSUnitsBySubject`, {
        params: { gradeId: this.gradeId, subjectCode, branchId: this.branchId },
      })
      .then((res: any) => {
        this.studyUnits = res.data.units.map((unit: { id: number; sUnitName: string }) => {
          return {
            value: unit.id.toString(),
            text: `${this.getGradeName()} ${unit.sUnitName}`,
          }
        })
      })
      .finally(() => {
        this.filterStudyUnits()
      })
  }

  private getGradeName(): string {
    const grade = this.tabBaseDatas.find((tabBaseData) => {
      return tabBaseData.id === this.gradeId
    })
    if (!grade) return ''
    return grade.name
  }
}
