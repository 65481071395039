import { Component, Vue } from 'vue-property-decorator'
import { ResultProgressesResponseType } from '@/models/api/resultProgresses'

export type SubjectProgressType = {
  name: string
  target: number | string
  ratio: number
  totalStep: number
  currentStep: number
  subjectCode: string
}

@Component
export class StepProgressMethods extends Vue {
  protected stepLabels = ['平均点到達レベル', '70点到達レベル', '80点到達レベル', '90点到達レベル', '100点到達レベル']

  protected parseStepProgresses(response: ResultProgressesResponseType): SubjectProgressType[] {
    return response.resultProgresses.map((progress) => {
      return {
        img: `/img/btn_kyouka/btn_kyouka_${progress.subjectCode}.png`,
        name: progress.subjectName,
        target: response.studentGoalTermExams.find((item) => item.subject_id === progress.subjectId)?.score || '-',
        ratio: progress.progress === 100 ? 0 : progress.progress,
        totalStep: 5,
        currentStep: progress.progress === 100 ? progress.currentStep + 1 : progress.currentStep,
        subjectCode: progress.subjectCode,
      }
    })
  }
}
